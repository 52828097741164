import { render, staticRenderFns } from "./manual-validation.vue?vue&type=template&id=1b97f086&scoped=true"
import script from "./manual-validation.vue?vue&type=script&lang=js"
export * from "./manual-validation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b97f086",
  null
  
)

export default component.exports